import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BridgeUserApp } from '@ih/interfaces';
import { LazySnackBarService } from '@ih/services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ih-bridge-user-add-app-dialog',
  templateUrl: './bridge-user-add-app-dialog.component.html',
  styleUrls: ['./bridge-user-add-app-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatToolbarModule,
    ReactiveFormsModule
  ]
})
export class BridgeUserAddAppDialogComponent {
  @HostBinding('class.ih-bridge-user-add-app-dialog') hostClass = true;

  loading$ = new BehaviorSubject(false);
  saving$ = new BehaviorSubject(false);
  appCtrl = new UntypedFormControl();
  filteredApps$: Observable<BridgeUserApp[]>;
  noAppFound$ = new BehaviorSubject(false);
  apps: BridgeUserApp[] = [];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  constructor(
    private matDialogRef: MatDialogRef<BridgeUserAddAppDialogComponent>,
    private http: HttpClient,
    private snackbar: LazySnackBarService,
    @Inject(MAT_DIALOG_DATA) public userId?: number
  ) {
    this.filteredApps$ = this.appCtrl.valueChanges.pipe(
      debounceTime(500),
      switchMap((q) => {
        if (!q) return of([]);
        return this.http.get<BridgeUserApp[]>(`/api/users/${userId}/unaddedApps`, { params: { q } }).pipe(
          map((filteredApps) => {
            filteredApps.forEach((u) => {
              u.isSelected = this.apps.some((app) => app.campaignId === u.campaignId);
            });
            return filteredApps;
          })
        );
      }),
      tap((users) => {
        if (users.length === 0) {
          this.noAppFound$.next(true);
        } else {
          this.noAppFound$.next(false);
        }
      })
    );
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  remove(app: BridgeUserApp): void {
    const index = this.apps.indexOf(app);
    if (index >= 0) {
      this.apps.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const picked = event.option.value;
    if (picked && !picked.isSelected) {
      this.apps.push(picked);
    }
    this.userInput.nativeElement.value = '';
    this.appCtrl.setValue('');
    this.noAppFound$.next(false);
  }

  add(): void {
    this.matDialogRef.close(true);
    this.http
      .post(
        `/api/users/${this.userId}/apps`,
        this.apps.map((a) => a.campaignId)
      )
      .subscribe(
        () => {
          this.snackbar.open(`App${this.apps.length > 1 ? 's' : ''} added`);
        },
        () => {
          this.saving$.next(false);
          this.snackbar.open('Something went wrong when adding these apps');
        }
      );
  }
}
