<mat-toolbar>
  <button mat-icon-button class="bridge-user-add-app-close-btn" (click)="cancel()">
    <mat-icon svgIcon="close"> </mat-icon>
  </button>
  <h4 mat-dialog-title class="bridge-user-add-app-title fx-flex">Add Hubs</h4>
  <span class="fx-flex"></span>
  <button mat-flat-button (click)="add()">ADD</button>
</mat-toolbar>

<div class="add-app-dialog-container">
  <mat-form-field class="add-app-chip-list" appearance="fill">
    <mat-label>Add hub</mat-label>
    <mat-chip-grid #chipList aria-label="Add hub">
      <mat-chip-row *ngFor="let app of apps" [removable]="true" (removed)="remove(app)">
        <div class="image">
          <img height="25" width="25" class="img-circle" [src]="app.iconUrl" />
        </div>
        <div class="details">
          <div class="name">&nbsp;{{ app.name }}</div>
        </div>
        <mat-icon matChipRemove svgIcon="close-circle"></mat-icon>
      </mat-chip-row>
      <input
        placeholder="Type a name or slug..."
        #userInput
        [formControl]="appCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
      />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="add-app-autocomplete">
      <mat-option
        *ngFor="let app of filteredApps$ | async"
        [value]="app"
        class="bridge-user-app-list-item fx-layout-row fx-layout-align-start-center"
        [ngClass]="{ selected: app.isSelected, 'is-member': app.isAdded }"
        [disabled]="app.isAdded || app.isSelected"
      >
        <svg *ngIf="app.isSelected" class="check" viewBox="0 0 24 24">
          <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
        </svg>
        <div class="bridge-user-app-list-img-container fx-layout-column">
          <img class="bridge-user-app-list-img" [src]="app.iconUrl" />
        </div>
        <div class="bridge-user-detail-row fx-layout-column fx-flex">
          <div class="bridge-user-fullname">{{ app.name }}</div>

          <div class="bridge-user-email" *ngIf="!app.owner">App has no owner</div>
          <div class="bridge-user-email" *ngIf="app.owner">
            Owner: {{ app.owner.fullName }} &middot; {{ app.owner.email }}
          </div>
        </div>
        <span class="fx-flex"></span>
        <span *ngIf="app.isAdded" class="already-added">Already added</span>
      </mat-option>
      <mat-option *ngIf="(noAppFound$ | async) && appCtrl.value">No matches found</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
